import React from "react";
import Hexagon from "./react-hexagon/Hexagon";

function ImageHexagon(props) {
  return (
    <div onClick={props.attributes.onClick} id={props.ID} className="hexagon">
      <Hexagon
        flatTop
        backgroundImage={props.attributes.myImage}
        backgroundScale={1.03}
        style={{
          strokeWidth: 8,
        }}
      />
    </div>
  );
}

ImageHexagon.propTypes = {};

export default ImageHexagon;
