import React from "react";

function Home() {
  return (
    <h2 style={{ textAlign: "center", fontSize: "1.25rem" }}>
      Machine Learning for Materials Design and Discovery
    </h2>
  );
}

Home.propTypes = {};

export default Home;
