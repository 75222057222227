import React from "react";
// var Cite = require("@citation-js/plugin-bibtex");
// var Cite = require("citation-js");
// import Cite from "citation-js";
// import parseBibTeX from "citation-js";
// import data from "./citation.bib";

function Blog() {
  // console.log(parseBibTeX(data));
  return (
    <div>
      <ul>
        <li>
          Saeed Moayedpour, Derek Dardzinski, Shuyang Yang, Andrea Hwang, and
          Noa Marom. "Structure Prediction of Epitaxial Inorganic Interfaces by
          Lattice and Surface Matching with Ogre." (2021).{" "}
          <a
            href="https://arxiv.org/abs/2103.13947"
            target="_blank"
            rel="noopener noreferrer"
          >
            link.
          </a>
        </li>
        <li>
          Shuyang Yang, Derek Dardzinski, Andrea Hwang, Dmitry I. Pikulin, Georg
          W. Winkler, and Noa Marom. "First principles feasibility assessment of
          a topological insulator at the InAs/GaSb interface." (2021).{" "}
          <a
            href="https://arxiv.org/abs/2101.07873"
            target="_blank"
            rel="noopener noreferrer"
          >
            link
          </a>
        </li>
        <li>
          Maituo Yu, Saeed Moayedpour, Shuyang Yang, Derek Dardzinski, Chunzhi
          Wu, Vlad S. Pribiag, and Noa Marom. "Dependence of the electronic
          structure of the EuS/InAs interface on the bonding configuration."
          (2021).{" "}
          <a
            href="https://arxiv.org/abs/2104.01623"
            target="_blank"
            rel="noopener noreferrer"
          >
            link
          </a>
        </li>
      </ul>
    </div>
  );
}

Blog.propTypes = {};

export default Blog;
