import React from "react";
import { useState } from "react";
import "./App.css";
import { Container } from "reactstrap";
import ImageHexagon from "./components/HexagonDefs/ImageHexagon";
import IconHexagon from "./components/HexagonDefs/IconHexagon";
import logo from "./structure.svg";
import logo2 from "./materials-science.svg";
import TwitterIcon from "./components/Icons/TwitterIcon";
import LinkedInIcon from "./components/Icons/LinkedInIcon";
import GitHubIcon from "./components/Icons/GitHubIcon";
import ResumeIcon from "./components/Icons/ResumeIcon";
import AboutIcon from "./components/Icons/AboutIcon";
import ResearchIcon from "./components/Icons/ResearchIcon";
import ContactIcon from "./components/Icons/ContactIcon";
import BlogIcon from "./components/Icons/BlogIcon";
import WebIcon from "./components/Icons/WebIcon";
import Home from "./components/Pages/Home";
import Resume from "./components/Pages/Resume";
import Research from "./components/Pages/Research";
import About from "./components/Pages/About";
import Contact from "./components/Pages/Contact";
import Blog from "./components/Pages/Blog";
import Website from "./components/Pages/Website";
import Headshot from "./components/Images/Headshot.jpg";

function HomePage() {
  var myImage = Headshot;
  var [page, setPage] = useState("Home");

  const hexagonProps = {
    center_top: {
      myImage: myImage,
      onClick: () => setPage("Home"),
    },
    left1_down1: {
      onClick: () => setPage("Resume"),
    },
    left2_down2: {
      onClick: () => setPage("Research"),
    },
    right1_down1: {
      onClick: () => setPage("About"),
    },
    right2_down2: {
      onClick: () => setPage("Contact"),
    },
    center_bottom: {
      href: "https://twitter.com/DardzinskiDerek",
    },
    left1_up1: {
      href: "https://www.linkedin.com/in/derek-dardzinski-11b5b6159/",
    },
    left2_up2: {
      onClick: () => setPage("Websites"),
    },
    right1_up1: {
      myImage: myImage,
      href: "https://github.com/DerekDardzinski/",
    },
    right2_up2: {
      myImage: myImage,
      onClick: () => setPage("Blog"),
    },
  };

  let middle;
  if (page === "Home") {
    middle = <Home />;
  } else if (page === "Resume") {
    middle = <Resume />;
  } else if (page === "Research") {
    middle = <Research />;
  } else if (page === "About") {
    middle = <About />;
  } else if (page === "Contact") {
    middle = <Contact />;
  } else if (page === "Blog") {
    middle = <Blog />;
  } else if (page === "Websites") {
    middle = <Website />;
  }

  console.log(page);

  return (
    <div id="wrapper">
      <Container id="main-container" fluid>
        <div className="main" id="main-top">
          <div id="background-top" className="background">
            <img src={logo} className="toplogo" alt=""></img>
            <h1 className="name">Derek Dardzinski</h1>
          </div>
          <ImageHexagon ID="center-top" attributes={hexagonProps.center_top} />
          <IconHexagon ID="left1-down1" attributes={hexagonProps.left1_down1}>
            <ResumeIcon />
            <text id="label-text" x="50%" y="80%">
              Resume
            </text>
          </IconHexagon>
          <IconHexagon ID="right1-down1" attributes={hexagonProps.right1_down1}>
            <AboutIcon />
            <text id="label-text" x="49%" y="80%">
              About Me
            </text>
          </IconHexagon>
          <IconHexagon ID="left2-down2" attributes={hexagonProps.left2_down2}>
            <ResearchIcon />
            <text id="label-text" x="50%" y="80%">
              Research
            </text>
          </IconHexagon>
          <IconHexagon ID="right2-down2" attributes={hexagonProps.right2_down2}>
            <ContactIcon />
            <text id="label-text" x="49%" y="80%">
              Contact Me
            </text>
          </IconHexagon>
        </div>
        <div className="main" id="main-middle">
          <div className="background" id="background-middle">
            {middle}
          </div>
        </div>
        <div className="main" id="main-bottom">
          <div id="background-bottom" className="background">
            <h3 className="school">
              <p style={{ opacity: "1", fontSize: "1.1rem" }}>
                Ph.D. Student in Materials Science
              </p>
              <p style={{ opacity: "1", fontSize: "1rem" }}>
                Carnegie Mellon University
              </p>
            </h3>
            <img src={logo2} className="bottomlogo" alt=""></img>
          </div>
          <IconHexagon
            ID="center-bottom"
            attributes={hexagonProps.center_bottom}
          >
            <TwitterIcon />
          </IconHexagon>
          <IconHexagon
            ID="left1-down1-bottom"
            attributes={hexagonProps.left1_up1}
          >
            <LinkedInIcon />
          </IconHexagon>
          <IconHexagon
            ID="right1-down1-bottom"
            attributes={hexagonProps.right1_up1}
          >
            <GitHubIcon />
          </IconHexagon>
          <IconHexagon
            ID="left2-down0-bottom"
            attributes={hexagonProps.left2_up2}
          >
            <WebIcon />
            <text id="label-text" x="49%" y="80%">
              Websites
            </text>
          </IconHexagon>
          <IconHexagon
            ID="right2-down0-bottom"
            attributes={hexagonProps.right2_up2}
          >
            <BlogIcon />
            <text id="label-text" x="49%" y="81%">
              Publications
            </text>
          </IconHexagon>
        </div>
      </Container>
    </div>
  );
}

export default HomePage;
