import React from "react";

function Contact() {
  return (
    <div>
      <h2 className="resume-h2">Email</h2>
      <hr></hr>
      <br />
      <p>ddardzin@andrew.cmu.edu</p>
      <h2 className="resume-h2">Phone</h2>
      <hr></hr>
      <br />
      <p>(425) 736-1294</p>
    </div>
  );
}

Contact.propTypes = {};

export default Contact;
