import React from "react";
import PropTypes from "prop-types";

function ContactIcon(props) {
  return (
    <svg
      role="img"
      viewBox="-340 -310 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
    >
      d="M120,297h-0.131c-5.523,0-10,4.477-10,10s4.477,10,10,10H120c5.523,0,10-4.477,10-10S125.523,297,120,297z"
      />
      <path d="M255,16C112.605,16,0,117.943,0,241c0,52.422,20.743,103.21,58.496,143.449c7.47,30.802-1.599,63.441-24.102,85.944    C24.986,479.801,31.645,496,45,496c42.744,0,83.944-16.75,114.574-46.271C189.866,460.53,221.918,466,255,466    c142.79,0,257-102.242,257-225C512,118.173,397.711,16,255,16z M255,436.2c-32.721,0-64.267-6.156-93.762-17.902    c-5.793-2.306-12.407-0.791-16.616,3.809c-18.118,19.799-41.671,33.605-67.327,39.951c14.136-27.089,17.749-59.022,9.144-89.275    c-0.678-2.386-1.938-4.566-3.666-6.345C48.742,331.385,29.8,286.837,29.8,241C29.8,133.477,130.935,45.8,255,45.8    c125.168,0,227.2,87.677,227.2,195.2S380.168,436.2,255,436.2z" />
      <path d="M135,196c-24.813,0-45,20.187-45,45s20.187,45,45,45s45-20.187,45-45S159.813,196,135,196z M135,256    c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15S143.271,256,135,256z" />
      <path d="M255,196c-24.813,0-45,20.187-45,45s20.187,45,45,45s45-20.187,45-45S279.813,196,255,196z M255,256    c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15S263.271,256,255,256z" />
      <path d="M377,196c-24.813,0-45,20.187-45,45s20.187,45,45,45s45-20.187,45-45S401.813,196,377,196z M377,256    c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15S385.271,256,377,256z" />
    </svg>
  );
}

ContactIcon.propTypes = {
  fill: PropTypes.string,
};

export default ContactIcon;
