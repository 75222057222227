import React from "react";
import PropTypes from "prop-types";

function ResumeIcon(props) {
  return (
    <svg role="img" viewBox="-17 -16 54 54" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.854 5.646l-4.5-4.5c-0.094-0.094-0.221-0.146-0.354-0.146h-9.5c-0.827 0-1.5 0.673-1.5 1.5v16c0 0.827 0.673 1.5 1.5 1.5h13c0.827 0 1.5-0.673 1.5-1.5v-12.5c0-0.133-0.053-0.26-0.146-0.354zM16.793 6h-3.293c-0.276 0-0.5-0.224-0.5-0.5v-3.293l3.793 3.793zM17 18.5c0 0.276-0.224 0.5-0.5 0.5h-13c-0.276 0-0.5-0.224-0.5-0.5v-16c0-0.276 0.224-0.5 0.5-0.5h8.5v3.5c0 0.827 0.673 1.5 1.5 1.5h3.5v11.5z"
        style={{ fill: props.fill }}
      />
    </svg>
  );
}

ResumeIcon.propTypes = {
  fill: PropTypes.string,
};

export default ResumeIcon;
