import React from "react";
import PropTypes from "prop-types";

function ResearchIcon(props) {
  return (
    <svg
      role="img"
      viewBox="-340 -310 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M120,297h-0.131c-5.523,0-10,4.477-10,10s4.477,10,10,10H120c5.523,0,10-4.477,10-10S125.523,297,120,297z" />
      <path d="M466,21H46C20.636,21,0,41.636,0,67v240c0,25.364,20.636,46,46,46h157.343l-7.723,75h-21.747    c-19.341,0-35.076,15.735-35.076,35.076V481c0,5.523,4.477,10,10,10h214.406c5.523,0,10-4.477,10-10v-17.924    c0-19.341-15.735-35.076-35.076-35.076h-21.748l-7.724-75H466c25.364,0,46-20.636,46-46V67C512,41.636,491.364,21,466,21z M46,41    h420c10.076,0,18.826,5.766,23.14,14.168H22.86C27.174,46.766,35.924,41,46,41z M338.126,448c8.312,0,15.076,6.763,15.076,15.076    V471H158.796v-7.924c0-8.313,6.763-15.076,15.076-15.076h30.771h102.712H338.126z M215.725,428l7.723-75h65.101l7.723,75H215.725z     M492,297H152.352c-5.523,0-10,4.477-10,10s4.477,10,10,10h337.646c-3.927,9.386-13.204,16-23.998,16H297.572h-83.146H46    c-10.794,0-20.071-6.614-23.997-16h66.509c5.523,0,10-4.477,10-10s-4.477-10-10-10H20V75.168h472V297z" />
      <path d="M211.838,99.327c-14.888,0-27,12.112-27,27c0,3.818,0.801,7.451,2.237,10.747l-17.912,17.912    c-4.748-2.523-10.158-3.959-15.899-3.959c-13.118,0-24.517,7.473-30.185,18.381l-15.619-2.823    c-2.5-12.32-13.414-21.622-26.461-21.622c-14.888,0-27,12.112-27,27s12.112,27,27,27c9.639,0,18.109-5.081,22.887-12.701    l15.629,2.825c1.881,15.726,14.53,28.189,30.345,29.769l3.246,16.158c-7.026,4.88-11.639,13.003-11.639,22.186    c0,14.888,12.112,27,27,27s27-12.112,27-27c0-13.482-9.933-24.687-22.865-26.683l-3.135-15.606    c10.592-5.766,17.796-17,17.796-29.884c0-5.741-1.436-11.151-3.959-15.899l17.96-17.96c3.249,1.388,6.822,2.158,10.572,2.158    c14.888,0,27-12.112,27-27S226.726,99.327,211.838,99.327z M81,178.964c-3.86,0-7-3.14-7-7s3.14-7,7-7s7,3.14,7,7    S84.86,178.964,81,178.964z M139.265,185.028c0-7.72,6.28-14,14-14c7.72,0,14,6.28,14,14c0,7.72-6.28,14-14,14    C145.545,199.028,139.265,192.748,139.265,185.028z M175.468,257.201c0,3.86-3.14,7-7,7s-7-3.14-7-7s3.14-7,7-7    S175.468,253.341,175.468,257.201z M211.838,133.327c-3.86,0-7-3.14-7-7c0-3.86,3.14-7,7-7s7,3.14,7,7    C218.838,130.187,215.698,133.327,211.838,133.327z" />
      <path d="M436.27,115H289c-5.523,0-10,4.477-10,10s4.477,10,10,10h147.27c5.522,0,10-4.477,10-10S441.793,115,436.27,115z" />
      <path d="M436.27,157.239H289c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10h147.27c5.522,0,10-4.477,10-10    C446.27,161.716,441.793,157.239,436.27,157.239z" />
      <path d="M436.27,199.478H289c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10h147.27c5.522,0,10-4.477,10-10    C446.27,203.955,441.793,199.478,436.27,199.478z" />
      <path d="M436.408,241.717h-0.138c-5.523,0-10,4.477-10,10s4.477,10,10,10h0.138c5.523,0,10-4.477,10-10    C446.408,246.194,441.931,241.717,436.408,241.717z" />
      <path d="M402.397,241.718H289c-5.523,0-10,4.477-10,10s4.477,10,10,10h113.397c5.523,0,10-4.477,10-10    C412.397,246.195,407.92,241.718,402.397,241.718z" />
    </svg>
  );
}

ResearchIcon.propTypes = {
  fill: PropTypes.string,
};

export default ResearchIcon;
