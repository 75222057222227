import React from "react";
import Hexagon from "./react-hexagon/ColorHexagon";

function IconHexagon(props) {
  return (
    <div onClick={props.attributes.onClick} id={props.ID} className="hexagon">
      <Hexagon
        flatTop
        href={props.attributes.href}
        target="_blank"
        style={{
          stroke: "rgb(200,200,200)",
          strokeWidth: "5",
        }}
      >
        {props.children}
      </Hexagon>
    </div>
  );
}

IconHexagon.propTypes = {};

export default IconHexagon;
