import React from "react";
import img from "../Images/band_dos_orbitals.png";
import symmetryImg from "../Images/symmetry-website.png";

function Website() {
  return (
    <div>
      <h2 className="resume-h2">
        <a
          href="https://github.com/DerekDardzinski/vaspvis"
          target="_blank"
          rel="noopener noreferrer"
        >
          VaspVis Visualization Package
        </a>
      </h2>
      <hr></hr>
      <br />
      <p>
        This Python package is a highly flexible band structure and density of
        states visualization package for VASP calculations. There are a total of
        48 standardized plot styles as well as more customizable options for
        more specific plots. An example of an orbital projected band structure
        and density of states plot for InAs is shown below. The documentation
        can be found{" "}
        <a
          href="https://vaspvis.readthedocs.io/en/latest/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
      <br />
      <a
        href="https://github.com/DerekDardzinski/vaspvis"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={img} className="band-img" alt="" />
      </a>
      <br />

      <h2 className="resume-h2">
        <a
          href="https://derekdardzinski.github.io/symmetry-website/#/"
          target="_blank"
          rel="noopener noreferrer"
        >
          3D Interactive Point Group Visualizations
        </a>
      </h2>
      <hr></hr>
      <br />
      <p>
        Crystallographic point groups are a difficult, but important topic for
        materials science students to understand. In most classrooms, these
        point groups are visualized using two-dimensional stereograpic
        projections that can further complicate the situation. During my
        crystallography class at CMU, I was introduced to a different method of
        visualizing the points groups, which was first introduced by Professor
        Marc De Graef. With his permission, I decided to bring his work to the
        web; making it more accessable for students to visualize, interact with,
        and understand the crystallographic point groups.
      </p>
      <br />
      <a
        href="https://derekdardzinski.github.io/symmetry-website/#/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={symmetryImg} className="symmetry-image" alt="" />
      </a>
      <br />

      <h2 className="resume-h2">
        <a
          href="http://xpsanalysis.herokuapp.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          XPS Analysis Website
        </a>
      </h2>
      <hr></hr>
      <br />
      <p>
        This web application was designed to give X-ray photoelectron
        spectroscopy users easy access to important parameters for the analysis
        of their spectra. Through this web application the photoionization cross
        section, differential photoionization cross section, and effective
        attenuation length can be calculated for any element or compound.
      </p>
    </div>
  );
}

Website.propTypes = {};

export default Website;
