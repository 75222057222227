import React from "react";

function Resume() {
  return (
    <div>
      <h2 className="resume-h2">Education</h2>
      <hr></hr>
      <h4 className="resume-h4">Carnegie Mellon University</h4>
      <p className="resume-dates">June 2020 - Current</p>
      <ul>
        <li>Ph.D. in Materials Science</li>
        <li>Expected graduation year: 2024</li>
      </ul>
      <h4 className="resume-h4">
        Oregon State University / Linn-Benton Community College
      </h4>
      <p className="resume-dates">September 2016 - June 2020</p>
      <ul>
        <li>Honors Bachelor of Science in Chemical Engineering</li>
        <li>
          <i>Summa Cum Laude</i>
        </li>
        <li>GPA: 3.93 / 4.00</li>
        <li>Graduation year: 2020</li>
      </ul>
      <br />

      <h2 className="resume-h2">Work Experience</h2>
      <hr></hr>

      <h4 className="resume-h4">
        Graduate Research Assistant - Carnegie Mellon University
      </h4>
      <p className="resume-dates">June 2020 - Current</p>
      <ul>
        <li>
          Developing a Python package for interface structure prediction and
          optimization with lattice matching and genetic algorithms.
        </li>
        <li>
          Calculating electronic properties of materials with density functional
          theory using the Vienna Ab initio Simulation Package (VASP).
        </li>
        <li>
          Developed a Python package for visualizing electronic structure
          calculations using VASP.
        </li>
      </ul>

      <h4 className="resume-h4">
        3D Printing Metal Jet Fusion Intern - Hewlett-Packard
      </h4>
      <p className="resume-dates">June 2020 - August 2020</p>
      <ul>
        <li>
          Used computer vision techniques to detect and locate printhead
          alignment marks on the metal powder bed.
        </li>
        <li>
          Developed a genetic algorithm for optimizing stacking orientation of
          half tone patterns to optimize ink placement.
        </li>
      </ul>

      <h4 className="resume-h4">
        Lab Worker - National Nanotechnology Coordinated Infrastructure
      </h4>
      <p className="resume-dates">December 2018 - June 2020</p>
      <ul>
        <li>
          In charge of inventory and ordering new supplies for the Herman
          Research Group.
        </li>
        <li>Maintained ultra-high vacuum systems.</li>
        <li>
          Mentored two summer interns to help them investigate the oxidation of
          transition metal dichalcogenides with X-ray photoelectron spectroscopy
          and atomic force microscopy.
        </li>
      </ul>

      <h4 className="resume-h4">
        Undergraduate Researcher - Prof. Gregory Herman Research Group
      </h4>
      <p className="resume-dates">July 2018 - June 2020</p>
      <ul>
        <li>
          Studied the effects of surface defects on the reactivity of MoS2 under
          near-ambient pressure conditions with ambient pressure X-ray
          photoelectron spectroscopy (APXPS).
        </li>
        <li>
          Analyzed APXPS spectra using CasaXPS and Python for a variety of
          materials.
        </li>
        <li>
          Assisted in the synthesis of PbSe quantum dots and fabricated thin
          film semiconductor devices.
        </li>
        <li>
          Evaluated film quality and surface roughness with atomic force
          microscopy (AFM).
        </li>
        <li>
          Analyzed quantum dot particle distributions from transmission electron
          microscope (TEM) images.
        </li>
        <li>
          Collaborated closely with a research scientist and graduate students.
        </li>
      </ul>
      <h4 className="resume-h4">
        Student Instructional Aide - Linn Benton Community College
      </h4>
      <p className="resume-dates">December 2017 - June 2018</p>
      <ul>
        <li>
          Tutored 8-10 students weekly in General Chemistry, Organic Chemistry,
          and Physics.
        </li>
      </ul>
      <br />

      <h2 className="resume-h2">Selected Course Work</h2>
      <hr></hr>
      <ul>
        <li>Introduction to Machine Learning (PhD) - Spring 2021</li>
        <li>Probability and Mathematical Statistics - Spring 2021</li>
      </ul>
      <br />

      <h2 className="resume-h2">Technical Skills</h2>
      <hr></hr>
      <h4 className="resume-h4">Programming</h4>
      <ul>
        <li>Python</li>
        <li>MatLab</li>
        <li>Julia</li>
        <li>JavaScript</li>
        <li>VASP</li>
      </ul>
      <h4 className="resume-h4">Experimental</h4>
      <ul>
        <li>X-ray Photoelectron Spectroscopy</li>
        <li>Atomic Force Microscopy</li>
        <li>Quantum Dot Synthesis</li>
        <li>Photolithography</li>
      </ul>
      <br />

      <h2 className="resume-h2">Python Skills</h2>
      <hr></hr>
      <h4 className="resume-h4">Familiar Packages</h4>
      <ul>
        <li>Matplotlib</li>
        <li>Numpy</li>
        <li>Pandas</li>
        <li>Scipy</li>
        <li>Sympy</li>
        <li>OpenCV</li>
        <li>PyMatGen</li>
        <li>ASE</li>
      </ul>
      <h4 className="resume-h4">Developed Packages</h4>
      <ul>
        <li>
          <a
            href="https://github.com/DerekDardzinski/vaspvis"
            target="_blank"
            rel="noopener noreferrer"
          >
            Vaspvis
          </a>
        </li>
      </ul>
      <br />

      <h2 className="resume-h2">Awards & Extracurriculars</h2>
      <hr></hr>
      <ul>
        <li>Oregon State University Dean’s List (x4)</li>
        <li>Future Leaders in Chemical Engineering National Award Symposium</li>
        <li>
          PNW American Vacuum Society Undergraduate Poster Award (1st Place)
        </li>
        <li>Honors College Ken and Paula Krane Experience Scholarship</li>
        <li>Evergreen State Academic Scholarship</li>
        <li>Northwest Athletic Conference Academic Excellence Award</li>
        <li>Varisty Baseball Scholarship at Linn-Benton Community College</li>
      </ul>
    </div>
  );
}

Resume.propTypes = {};

export default Resume;
