import React from "react";

function Research() {
  return (
    <div>
      <h2 className="resume-h2">Current Research</h2>
      <hr></hr>
      <h4 className="resume-h4">Interface Structure Prediction</h4>
      <p className="resume-dates" style={{ marginBottom: "0.5rem" }}>
        June 2020 - Current
      </p>
      <p>
        My current research, under{" "}
        <a
          href="https://www.noamarom.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Professor Noa Marom
        </a>
        , is focused on developing a Python package to predict, and optimize
        hybrid superconductor / semiconductor / ferromagentic interfaces with
        lattice matching and genetic algorithms. This work has applications in
        quantum computing, as this material system has shown promise for
        creating exotic topological quantum states which can be encoded by fault
        tolarent quantum bits.
      </p>
      <h4 className="resume-h4">
        Ab-initio Simulations of Surfaces and Interfaces
      </h4>
      <p className="resume-dates" style={{ marginBottom: "0.5rem" }}>
        June 2020 - Current
      </p>
      <p>
        Another important aspect of my current research under{" "}
        <a
          href="https://www.noamarom.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Professor Noa Marom
        </a>
        , is focused on calculating the electronic structure of hybrid
        superconductor / semiconductor / ferromagentic interfaces. For this
        project, I work with experimental collaborators to help them further
        understand their results using my simulations. Most commonly, this
        involves using band structure calculations to compare with
        Angle-resolved photoemission spectroscopy (ARPES) data, as well as
        scanning tunneling microscopy (STM) simulations and density of states
        calculations to compare to experimental STM and scanning tunneling
        spectroscopy (STS) data.
      </p>
      <br />
      <h2 className="resume-h2">Previous Research</h2>
      <hr></hr>
      <h4 className="resume-h4">X-ray Photoelectron Spectroscopy</h4>
      <p className="resume-dates" style={{ marginBottom: "0.5rem" }}>
        December 2018 - June 2020
      </p>
      <p>
        During my undergraduate studies I used ambient pressure X-ray
        photoelectron spectroscopy to study the effect of surface defects on the
        surface reactivity of molybdenum disulfide under a low pressure of water
        vapor. This work was advised by{" "}
        <a
          href="http://research.engr.oregonstate.edu/herman/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Professor Gregory Herman
        </a>
        .
      </p>
      <h4 className="resume-h4">
        Quantum Dot Synthesis / Thin Film Device Fabrication
      </h4>
      <p className="resume-dates" style={{ marginBottom: "0.5rem" }}>
        July 2018 - December 2018
      </p>
      <p>
        For my first undergraduate project, I was able to synthesize and
        characterize quantum dots and use them to create thin film devices for
        applications in radiation sensing. This work was also advised by{" "}
        <a
          href="http://research.engr.oregonstate.edu/herman/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Professor Gregory Herman
        </a>
        .
      </p>
      <br></br>
    </div>
  );
}

Research.propTypes = {};

export default Research;
