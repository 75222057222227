import React from "react";

function About() {
  return (
    <div>
      <h2 className="resume-h2">About Me</h2>
      <hr />
      <br />
      <p className="about-p">
        I am currently a first year Ph.D. student at Carnegie Mellon University
        studying Materials Science. My research uses computational methods such
        as density functional theory and genetic algorithms to explore the
        structure space of inorganic crystalline interfaces for applications in
        quantum computing.
      </p>
      <br></br>
      <p className="about-p">
        Before attending Carnegie Mellon, I recieved my H.B.S in Chemical
        Engineering at Oregon State University. My undergraduate research and
        honors thesis focused on experimental surface science with ambient
        pressure X-ray photoelectron spectroscopy. I eventually discovered
        computational materials science and began focusing my energy towards
        that field.
      </p>
      <br></br>
      <p className="about-p">
        I've developed a passion for learning, communicating science, and
        programming. My hope is to contribute to my field through the
        developement of open source software, and communicate my research not
        only through publications, but also through informative web based
        applications. Beyond academics, I am an avid rock climber and enjoy
        spending my spare time in the climbing gym or outdoors.
      </p>
    </div>
  );
}

About.propTypes = {};

export default About;
